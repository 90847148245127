.btn-arrow-right, .btn-arrow-left {
  position: relative;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 0 !important;
  margin-right: 1px; }

.btn-arrow-right[disabled], .btn-arrow-left[disabled] {
  opacity: 1.00; }

.btn-arrow-right {
  &:before, &:after {
    content: "";
    position: absolute;
    top: 4px;
    height: 24px;
    width: 24px;
    background: inherit;
    border: inherit;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-radius: 0 !important; } }

.btn-arrow-left {
  &:before, &:after {
    content: "";
    position: absolute;
    top: 4px;
    height: 24px;
    width: 24px;
    background: inherit;
    border: inherit;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-radius: 0 !important; } }

.btn-arrow-right:before, .btn-arrow-left:before {
  left: -13px; }

.btn-arrow-right:after, .btn-arrow-left:after {
  right: -13px; }

.btn-arrow-right.btn-arrow-left, .btn-arrow-left.btn-arrow-left {
  padding-right: 36px; }

.btn-arrow-right.btn-arrow-left {
  &:before, &:after {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg); } }

.btn-arrow-left.btn-arrow-left {
  &:before, &:after {
    -webkit-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    transform: rotate(225deg); } }

.btn-arrow-right.btn-arrow-right, .btn-arrow-left.btn-arrow-right {
  padding-left: 36px; }

.btn-arrow-right.btn-arrow-right {
  &:before, &:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); } }

.btn-arrow-left.btn-arrow-right {
  &:before, &:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); } }

.btn-arrow-right:after, .btn-arrow-left:before {
  z-index: 3; }

.btn-arrow-right:before, .btn-arrow-left:after {
  background-color: #fff; }

.btn-lg {
  &.btn-arrow-right, &.btn-arrow-left {
    padding-left: 22px;
    padding-right: 22px;
    margin-right: 0px; } }

.btn-group-lg > {
  .btn-arrow-left, .btn-arrow-right {
    padding-left: 22px;
    padding-right: 22px;
    margin-right: 0px; } }

.btn-lg {
  &.btn-arrow-right {
    &:before, &:after {
      top: 6px;
      height: 32px;
      width: 32px; } }

  &.btn-arrow-left {
    &:before, &:after {
      top: 6px;
      height: 32px;
      width: 32px; } } }

.btn-group-lg > {
  .btn-arrow-left {
    &:before, &:after {
      top: 6px;
      height: 32px;
      width: 32px; } }

  .btn-arrow-right {
    &:before, &:after {
      top: 6px;
      height: 32px;
      width: 32px; } } }

.btn-lg {
  &.btn-arrow-right:before, &.btn-arrow-left:before {
    left: -16px; } }

.btn-group-lg > {
  .btn-arrow-left:before, .btn-arrow-right:before {
    left: -16px; } }

.btn-lg {
  &.btn-arrow-right:after, &.btn-arrow-left:after {
    right: -16px; } }

.btn-group-lg > {
  .btn-arrow-left:after, .btn-arrow-right:after {
    right: -16px; } }

.btn-lg {
  &.btn-arrow-right.btn-arrow-left, &.btn-arrow-left.btn-arrow-left {
    padding-right: 44px; } }

.btn-group-lg > {
  .btn-arrow-left.btn-arrow-left, .btn-arrow-right.btn-arrow-left {
    padding-right: 44px; } }

.btn-lg {
  &.btn-arrow-right.btn-arrow-right, &.btn-arrow-left.btn-arrow-right {
    padding-left: 44px; } }

.btn-group-lg > {
  .btn-arrow-left.btn-arrow-right, .btn-arrow-right.btn-arrow-right {
    padding-left: 44px; } }

.btn-sm {
  &.btn-arrow-right, &.btn-arrow-left {
    padding-left: 14px;
    padding-right: 17px;
    margin-right: -1px; } }

.btn-group-sm > {
  .btn-arrow-left, .btn-arrow-right {
    padding-left: 14px;
    padding-right: 17px;
    margin-right: -1px; } }

.btn-sm {
  &.btn-arrow-right {
    &:before, &:after {
      top: 3px;
      height: 21px;
      width: 22px; } }

  &.btn-arrow-left {
    &:before, &:after {
      top: 3px;
      height: 21px;
      width: 22px; } } }

.btn-group-sm > {
  .btn-arrow-left {
    &:before, &:after {
      top: 3px;
      height: 21px;
      width: 22px; } }

  .btn-arrow-right {
    &:before, &:after {
      top: 3px;
      height: 21px;
      width: 22px; } } }

.btn-sm {
  &.btn-arrow-right:before, &.btn-arrow-left:before {
    left: -10px; } }

.btn-group-sm > {
  .btn-arrow-left:before, .btn-arrow-right:before {
    left: -10px; } }

.btn-sm {
  &.btn-arrow-right:after, &.btn-arrow-left:after {
    right: -10px; } }

.btn-group-sm > {
  .btn-arrow-left:after, .btn-arrow-right:after {
    right: -10px; } }

.btn-sm {
  &.btn-arrow-right.btn-arrow-left, &.btn-arrow-left.btn-arrow-left {
    padding-right: 28px; } }

.btn-group-sm > {
  .btn-arrow-left.btn-arrow-left, .btn-arrow-right.btn-arrow-left {
    padding-right: 28px; } }

.btn-sm {
  &.btn-arrow-right.btn-arrow-right, &.btn-arrow-left.btn-arrow-right {
    padding-left: 28px; } }

.btn-group-sm > {
  .btn-arrow-left.btn-arrow-right, .btn-arrow-right.btn-arrow-right {
    padding-left: 28px; } }

.btn-xs {
  &.btn-arrow-right, &.btn-arrow-left {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -1px; } }

.btn-group-xs > {
  .btn-arrow-left, .btn-arrow-right {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: -1px; } }

.btn-xs {
  &.btn-arrow-right {
    &:before, &:after {
      top: 3px;
      height: 14px;
      width: 14px; } }

  &.btn-arrow-left {
    &:before, &:after {
      top: 3px;
      height: 14px;
      width: 14px; } } }

.btn-group-xs > {
  .btn-arrow-left {
    &:before, &:after {
      top: 3px;
      height: 14px;
      width: 14px; } }

  .btn-arrow-right {
    &:before, &:after {
      top: 3px;
      height: 14px;
      width: 14px; } } }

.btn-xs {
  &.btn-arrow-right:before, &.btn-arrow-left:before {
    left: -7px; } }

.btn-group-xs > {
  .btn-arrow-left:before, .btn-arrow-right:before {
    left: -7px; } }

.btn-xs {
  &.btn-arrow-right:after, &.btn-arrow-left:after {
    right: -7px; } }

.btn-group-xs > {
  .btn-arrow-left:after, .btn-arrow-right:after {
    right: -7px; } }

.btn-xs {
  &.btn-arrow-right.btn-arrow-left, &.btn-arrow-left.btn-arrow-left {
    padding-right: 20px; } }

.btn-group-xs > {
  .btn-arrow-left.btn-arrow-left, .btn-arrow-right.btn-arrow-left {
    padding-right: 20px; } }

.btn-xs {
  &.btn-arrow-right.btn-arrow-right, &.btn-arrow-left.btn-arrow-right {
    padding-left: 20px; } }

.btn-group-xs > {
  .btn-arrow-left.btn-arrow-right, .btn-arrow-right.btn-arrow-right {
    padding-left: 20px; } }

.btn-group > {
  .btn-arrow-left {
    &:hover, &:focus {
      z-index: initial; } }

  .btn-arrow-right {
    &:hover, &:focus {
      z-index: initial; }

    + .btn-arrow-right {
      margin-left: 0px; } }

  .btn-arrow-left + .btn-arrow-left {
    margin-left: 0px; }

  .btn:not(.btn-arrow-right):not(.btn-arrow-left) {
    z-index: 1; } }
