.search-bar {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid rgba(218, 220, 224, 0.5);
  background: rgba(60, 60, 60, .05);
  &.search-product {
    border-radius: 5px; }
  .btn {
    background: #0067ac !important;
    border-color: #0067ac !important;
    font-size: 13px; }
  .icon {
    font-size: 18px;
    padding: 0 2px 5px 13px;
    color: #666;
    cursor: pointer; }
  .icon:hover {
    color: #000; }
  .search {
    flex-grow: 1;
    input {
      background: transparent;
      border: 0;
      border-radius: 0; } } }
