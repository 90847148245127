#modal-add-tag {
  width: 544px !important;
  .modal-title {
    color: #0067ac; } }
.form-check-input:checked {
  background-color: #0067ac;
  border-color: #0067ac; }
.create-tag {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  .input-tag {
    width: 60%;
    min-width: 60%;
    margin-right: 30px;
    margin-left: 30px;
    .error {
      font-size: 13px;
      color: #fb3535; }
    .uk-input {
      border-radius: 5px !important;
      font-size: 14px !important;
      height: 34px !important; } } }
.list-tags {
  margin-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  max-height: 350px;
  overflow-y: auto;
  .tag-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f1f1f1;
    .color {
      margin-left: 30px;
      min-width: 25px;
      height: 25px; }
    .tag-name {
      display: flex;
      justify-content: space-between;
      margin-left: 30px;
      width: 100%;
      label {
        cursor: pointer;
        font-weight: 600;
        font-size: 14px; }
      .remove-tag {
        cursor: pointer; } } } }

@media screen and (min-width: 700px) {
  .modal-dialog {
    max-width: 600px; } }
