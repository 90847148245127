.nav-bar {
  padding: 5px 15px 0 15px;
  height: 54px;
  border-bottom: 1px solid #dadce0;
  background: #f4f7ff;
  .topbar-left {
    width: 100%;
    display: flex; }
  .topbar-right {
    width: 100px;
    max-width: 100px; }
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    max-width: 70px;
    position: relative;
    margin-right: 10px;
    &.active {
      border-bottom: 3px solid #0067ac; }
    .nav-noti {
      font-size: 10px;
      border: solid 1px #ff0000;
      border-radius: 10px;
      margin-top: 18px;
      background-color: #ff0000;
      color: #ffffff;
      position: absolute;
      top: -10px;
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.noti-social {
        top: -16px; } }
    .nav-chat-noti {
      left: 55%; }

    cursor: pointer;
    color: #666;
    .icon {
      font-size: 18px;
      margin-top: -2px;
      margin-bottom: -2px;
      svg {
        font-size: 30px; } }
    .text {
      font-size: 9px; } }
  .item:hover {
    color: #000; } }
