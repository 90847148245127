.bottom-bar-conversation {
  width: 100%;
  min-height: 54px;
  max-height: 124px;
  border-top: 1px solid rgba(218, 220, 224, 0.5);
  background: #fff;
  padding: 5px;
  .profile {
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 7px 12px; }
  .nav {
    display: flex;
    padding-right: 9px;
    .button {
      color: #666;
      font-size: 13px;
      padding: 9px;
      cursor: pointer; }
    .button:hover {
      color: #000; } }

  > textarea.search-input {
    flex-grow: 1;
    width: calc(100% - 87px);
    height: 40px;
    font-size: 14px;
    padding: 0 8px;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .smile {
    margin-right: 6px; }
  .image-attach {
    margin: 0; }
  .attach {
    margin-left: 6px; } }
.picker {
  position: relative;
  width: 0;
  height: 0;
  bottom: 410px + 41px;
  left: 0; }
.emoji-mart {
  border: none !important;
  border-radius: 0 !important; }
.file-input {
  visibility: hidden;
  width: 0;
  height: 0;
  flex-grow: 0 !important;
  padding: 0 !important;
  margin: 0 !important; }

.emoji-mart-preview-shortnames {
  display: none !important; }
.emoji-mart-preview-emoticons {
  display: none !important; }

.prevent-events {
  pointer-events: none;
  opacity: 0.3; }
.bottom-bar-tool {
  position: relative;
  .button.button-send {
    position: absolute;
    margin: 0 13px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    right: 0;
    bottom: 3px;
    background: #E7AB3C;
    color: #fff;
    border-radius: 5px;
    img {
      filter: brightness(0) invert(1);
      margin-right: 7px; } } }

