.product-item {
  position: relative;
  display: flex;
  margin-bottom: 5px;
  padding: 5px 0;
  border-bottom: 1px solid #f1f1f1;
  .left {
    width: 100px;
    min-width: 100px;
    img {
      width: 100%; } }
  .right {
    margin-left: 10px;
    width: 100%;
    .product-name {
      font-weight: 600; }
    .btn-detail-product {
      color: #0028fa;
      font-size: 13px;
      a {
        color: #0028fa !important; } }
    p {
      font-size: 14px;
      margin-bottom: 8px;
      margin-top: 0; } } }
