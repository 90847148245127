.search-bar {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(218, 220, 224, 0.5);
  background: rgba(60, 60, 60, .05);
  .selection-tag {
    position: relative;
    cursor: pointer;
    .bg-default {
      margin-right: 10px; }
    .selection-tag-icon {
      position: {}
      .icon-check {
        position: absolute;
        bottom: 9px;
        right: -5px;
        color: #d00000;
        font-size: 16px; } }
    .select-tag-lists {
      position: absolute;
      top: 35px;
      right: -80px;
      width: fit-content;
      min-width: 200px;
      background: #fff;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      z-index: 9;
      .wrapper {
        max-height: 500px;
        overflow: auto;
        margin-bottom: 10px;
        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 12px;
          padding-bottom: 12px;
          cursor: pointer;
          border-bottom: 1px solid #f1f1f1;
          .color {
            margin-right: 10px;
            width: 15px !important;
            height: 15px !important; }
          .tag-name {
            font-size: 14px;
            margin-right: 10px; }
          .icon-check {
            color: #0067ac;
            font-size: 17px; } } } } }
  .icon {
    font-size: 18px;
    padding: 0 2px 5px 13px;
    color: #666;
    cursor: pointer; }
  .icon:hover {
    color: #000; }
  .search {
    flex-grow: 1;
    input {
      background: transparent;
      border: 0;
      border-radius: 0;
      font-size: 14px; } } }
