.search-page-wrap {
  &.show {
    display: block; }
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 5;
  .search-page-header {
    padding: 10px; } }

@media screen and (min-width: 700px) {
  .mobile {
    display: none; } }
