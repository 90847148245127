#modal-report {
  max-width: 80vw !important; }

.modal {
  z-index: 101 !important; }

.modal-backdrop {
  z-index: 100 !important; }

.tab-content {
  .active {
    color: #333 !important; } }

.card-mess-trans .ant-card-head {
  background: #e2e9f1;
  border-bottom: 3px solid #fff !important; }

.card-mess-trans .ant-card-body {
  background: #e2e9f1; }
