html, body, #root, .theme {
  background: #303841;
  // position: fixed
  width: 100%;
  height: 100%;
 }  // overflow: hidden

.theme.light {
  background: #fff; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

// Custom variables and overwrites.
$global-link-color: #DA7D02;

// UIkit defaults.
@import "../node_modules/uikit/src/scss/variables-theme.scss";
@import "../node_modules/uikit/src/scss/mixins-theme.scss";

// Mixin overwrites.
@mixin hook-card() {
  color: #000; }

// Import UIkit.
@import "../node_modules/uikit/src/scss/uikit-theme.scss";

.uk-dropdown {
  border: 1px solid rgba(218, 220, 224, 0.5);
  box-shadow: none; // -0 0 3px rgba(0, 0, 0, 0.05)
  padding: 2px;
  min-width: 150px;
  font-size: 12px;
  .link {
    padding: 8px 12px;
    color: #666;
    cursor: pointer;
    display: flex;
    .icon {
      padding-left: 4px;
      margin-top: -2px; } }
  .link:hover {
    background: rgba(218, 220, 224, 0.2); }
  .divider {
    width: 100%;
    height: 0;
    border-bottom: 1px solid rgba(218, 220, 224, 0.5); } }

.uk-button.uk-button-honey {
  background: #da7d02;
  color: #ffffff; }

.btn.btn-default {
  background-color: #b9b9b9 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mr-2 {
  margin-right: 10px; }

.text-bold {
  font-weight: 600 !important; }

.load-more {
  display: block;
  text-align: center;
  color: #0067ac;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer; }

.fs-12 {
  font-size: 12px !important; }

.fs-13 {
  font-size: 13px !important; }

.fs-14 {
  font-size: 14px !important; }

.fs-15 {
  font-size: 15px !important; }

.cursor-pointer {
  cursor: pointer; }

.rorate {
  animation: rotate 1.5s linear infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.ant-select-selector {
  border-radius: 4px !important; }

.ant-picker {
  border-radius: 4px !important; }
