.loading-common {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e0e0e085;
  z-index: 999999;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center; }
