.color {
  width: 20px;
  height: 20px;
  border-radius: 50%; }
.swatch {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer; }
.popover {
  position: absolute;
  z-index: 2;
  top: 92px;
  left: 10px; }
.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px; }
